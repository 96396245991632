.frame-type-form_formframework {
    form {
        max-width: 60rem;
        margin: 0 auto;
    }

    .frame-type-text {
        p {
            width: 100%;
        }
    }

    .help-block {
        font-size: $small-font-size;

        &.error {
            color: $red;
        }
    }
}

.control-label {
    font-weight: $font-weight-bold;
}
